import React, { useState, useEffect } from 'react';
import { Layout, Table, Input, Button } from 'antd';
import axios from 'axios';
import NavBar from '../../../components/navbar/navbar.component';

const { Search } = Input;
const { Header, Content } = Layout;

const SellPage = () => {
  const [originalData, setOriginalData] = useState([]); // เก็บข้อมูลต้นฉบับ
  const [userData, setUserData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get('https://server.terminal-xpress.com/api/sell-log')

      .then((response) => {
        const data = response.data;
        setUserData(data);
        setOriginalData(data); // เซ็ตข้อมูลต้นฉบับที่โหลดมา
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredData = originalData.filter((user) => {
      return user.username.includes(value) || user.id.includes(value);
    });
    setUserData(filteredData);
  };

  const resetSearch = () => {
    setSearchValue('');
    setUserData(originalData); // ใช้ข้อมูลต้นฉบับเมื่อรีเซ็ตค้นหา
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'ชื่อผู้ใช้',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'ราคาตัวแทน',
      dataIndex: 'product_cost',
      key: 'product_cost',
      render: (product_cost) => (
        <span>
          {parseFloat(product_cost).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'ผลตอบแทน',
      dataIndex: 'commission',
      key: 'commission',
      render: (cost) => (
        <span>
          {parseFloat(cost).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'เวลา',
      dataIndex: 'time_stamp',
      key: 'time_stamp',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
    },
    
  ];

  return (
    <Layout style={{ height: '100vh', padding: 0 }}>
      <Layout>
      <NavBar />
        <Header style={{ padding: '0 16px', background: '#001529', color: 'white' }}>
        <h1 style={{ fontSize: '24px', margin: '0', fontFamily: 'Noto Sans Thai' }}>บันทึกการร่วมซื้อ</h1>
        </Header>
        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
          <Search
            placeholder="ค้นหา..."
            enterButton="ค้นหา"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearch}
            style={{ marginBottom: '16px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  <Button type="primary" onClick={resetSearch}>รีเซ็ตค้นหา</Button>
</div>

          <Table dataSource={userData} columns={columns} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default SellPage;
