import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Table, Input, Button, message } from 'antd';
import axios from 'axios';
import NavBar from '../../../components/navbar/navbar.component';

const { Search } = Input;
const { Header, Content } = Layout;

const DeliveryPage = () => {
  const [userData, setUserData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get('https://server.terminal-xpress.com/api/delivery-log')

      .then((response) => {
        setUserData(response.data.combinedData);
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };

  const allowDelivery = (record) => {
    const postData = {
      username: record.username,
    };

    axios.put(`https://server.terminal-xpress.com/api/delivery-log/${record.id}`, postData)

      .then((response) => {
        if (response.status === 200) {
          message.success('อนุมัติสำเร็จ');
          fetchData();
        } else {
          console.error('เกิดข้อผิดพลาดในการอนุมัติ');
          message.error('เกิดข้อผิดพลาดในการอนุมัติ');
        }
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการอนุมัติ: ', error);
        message.error('เกิดข้อผิดพลาดในการอนุมัติ');
      });

    // เพิ่มเครดิตให้กับผู้ใช้
    addCredit(record);
  };

  const addCredit = useCallback((record) => {
    const cost = parseFloat(record.product_cost);
    const commission = parseFloat(record.commission);
    const postData = {
      username: record.username,
      cost: cost,
      commission: commission
    };

    axios.put(`https://server.terminal-xpress.com/api/credit-add`, postData)

      .then((response) => {
        if (response.status === 200) {
          message.success('เพิ่มเครดิตสำเร็จ');
          fetchData();
        } else {
          console.error('เกิดข้อผิดพลาดในการเพิ่มเครดิต');
          message.error('เกิดข้อผิดพลาดในการเพิ่มเครดิต');
        }
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการเพิ่มเครดิต: ', error);
        message.error('เกิดข้อผิดพลาดในการเพิ่มเครดิต');
      });
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);

    const filteredData = userData.filter((user) => {
      return user.username.includes(value) || user.phone.includes(value);
    });

    setUserData(filteredData);
  };

  const resetSearch = () => {
    setSearchValue('');
    fetchData();
  };

  const columns = [
    {
      title: 'เลขที่',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ชื่อผู้ใช้',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'ราคาตัวแทน',
      dataIndex: 'product_cost',
      key: 'product_cost',
      render: (product_cost) => (
        <span>
          {parseFloat(product_cost).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'คอมมิชชัน',
      dataIndex: 'commission',
      key: 'commission',
      render: (commission) => (
        <span>
          {parseFloat(commission).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'วัน/เวลา',
      dataIndex: 'time_stamp',
      key: 'time_stamp',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'ดำเนินการ',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        if (record.status === 'รอการอนุมัติ') {
          return (
            <Button
              type="primary"
              onClick={() => allowDelivery(record)}
            >
              อนุมัติ
            </Button>
          );
        } else {
          return (
            <span>อนุมัติแล้ว</span>
          );
        }
      },
    },
  ];

  return (
    <Layout style={{ height: '100vh', padding: 0 }}>
      <Layout>
        <NavBar />
        <Header style={{ padding: '0 16px', background: '#001529', color: 'white' }}>
          <h1 style={{ fontSize: '24px', margin: '0', fontFamily: 'Noto Sans Thai' }}>ควบคุมการจัดส่ง</h1>
        </Header>
        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
          <Search
            placeholder="ค้นหา..."
            enterButton="ค้นหา"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearch}
            style={{ marginBottom: '16px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Button type="primary" onClick={resetSearch}>รีเซ็ตค้นหา</Button>
          </div>

          <Table dataSource={userData} columns={columns} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DeliveryPage;
