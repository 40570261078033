import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Modal } from 'antd';
import styled from 'styled-components';
import { CameraOutlined } from '@ant-design/icons';
import MenuBar from '../../components/menu-bar/menu-bar.component';
import axios from 'axios';
import { useSelector } from 'react-redux';

const Container = styled.div`
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: space-between;
  padding: 20px;
  @media (max-width: 768px) {
    width: 95%;
    margin: 0 5px;
    padding: 0 5px;
    height: 80vh;
  }
`;

const ChatContainer = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 4px;
  background-color: white;
  font-family: 'helvetica';
`;

const Bubble = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  width: 35%;
  line-height: 25px;
  word-wrap: break-word; /* บังคับให้ข้อความขึ้นบรรทัดใหม่ */
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
const LeftBubble = styled(Bubble)`
  background-color: #f2f2f2;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const RightBubble = styled(Bubble)`
  background-color: #ddd;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 60%;
`;
const LeftTime = styled.div`
  color: grey;
  text-align: right;
  margin-top: 3px;
  font-size: 8px;
  @media (max-width: 768px) {
    font-size: 6px;
  }
`;
const RightTime = styled.div`
  color: grey;
  text-align: right;
  margin-top: 3px;
  font-size: 8px;
  @media (max-width: 768px) {
    font-size: 6px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const ImgContainer = styled.img`
width: 100%;
height: auto;
margin: 5px;
`;
const ImgZoom = styled.img`
width: 100%;
`;
const FakeUpload = styled.label`
  border: none;
  padding: 0 16px;
  cursor: pointer;
  background-color: transparent;
  color: #000;
  border-radius: 4px;
  font-size: 20px;
`
const InputFile = styled.input`
display: none;
`

const ChatApp = () => {
  const chatContainerRef = useRef(null);
  const currentUser = useSelector((state) => state.auth.currentUser.username);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [chatHistoryUser2Back, setChatHistoryUser2Back] = useState([]);
  const [chatHistoryBack2User, setChatHistoryBack2User] = useState([]);
  const [image, setImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    Modal.info({
      title: 'ขยายภาพ',
      content: <img src={imageUrl.replace(
        'https://terminal-xpress.com/',
        'https://server.terminal-xpress.com/'
      )} alt="Enlarged" style={{ width: '100%' }} />,
      okText: 'Close',
    });
  };

  const fetchChatHistory = async () => {
    try {
      const [user2Back, back2User] = await Promise.all([
        axios.get('https://server.terminal-xpress.com/api/user2back-log', {
          params: { sender: currentUser },
        }),
        axios.get('https://server.terminal-xpress.com/api/back2user-log', {
          params: { reciepient: currentUser },
        }),
      ]);

      setChatHistoryUser2Back(user2Back.data);
      setChatHistoryBack2User(back2User.data);
      setMessages([...user2Back.data, ...back2User.data]);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchChatHistory();

      const interval = setInterval(() => {
        fetchChatHistory();
      }, 30000); // Poll every 5 seconds

      return () => clearInterval(interval);
    }
  }, [currentUser]);

  const sendMessage = () => {
    if (messageInput) {
      axios
        .post('https://server.terminal-xpress.com/api/user2back', {
          sender: currentUser,
          message: messageInput,
        })
        .then(() => {
          setMessageInput('');
          fetchChatHistory(); // Fetch the updated chat history after sending a message
        })
        .catch((error) => {
          console.error('Error sending message:', error);
        });
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      const formData = new FormData();
      formData.append('sender', currentUser);
      formData.append('image', file);

      try {
        await axios.post('https://server.terminal-xpress.com/api/user2back-img', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setImage(null);
        fetchChatHistory(); // Fetch the updated chat history after uploading an image
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };


  return (
    <div>
      <MenuBar />
      <Container>
        <h1>แชทกับฝ่ายบริการ</h1>
        <ChatContainer ref={chatContainerRef}>
  {chatHistoryBack2User.concat(chatHistoryUser2Back).sort((a, b) => a.time_stamp.localeCompare(b.time_stamp)).map((message, index) => (
    message.sender === currentUser ? (
      <RightBubble key={index}>
        {message.type === 'message' ? (
          <span>{message.message}</span>
        ) : (
          message.type === 'image' ? (
            <ImgContainer
            src={message.message.replace(
              'https://terminal-xpress.com/',
              'https://server.terminal-xpress.com/'
            )}
                        onClick={() => handleImageClick(message.message)} // Handle image click
                        style={{ cursor: 'pointer' }}
                      />
          ) : (
            // เพิ่มเงื่อนไขที่คุณต้องการแสดงในกรณีอื่น ๆ ที่ message.type ต้องการ
            null
          )
        )}
        <RightTime>{message.time_stamp}</RightTime>
      </RightBubble>
    ) : (
      <LeftBubble key={index}>
        {message.type === 'message' ? (
          <span>{message.message}</span>
        ) : (
          message.type === 'image' ? (
            <ImgContainer
            src={message.message.replace(
              'https://terminal-xpress.com/',
              'https://server.terminal-xpress.com/'
            )}
                        onClick={() => handleImageClick(message.message)} // Handle image click
                        style={{ cursor: 'pointer' }}
                      />
          ) : (
            // เพิ่มเงื่อนไขที่คุณต้องการแสดงในกรณีอื่น ๆ ที่ message.type ต้องการ
            null
          )
        )}
        <LeftTime>{message.time_stamp}</LeftTime>
      </LeftBubble>
    )
  ))}
</ChatContainer>
        <InputContainer>
        <FakeUpload>
          <InputFile
          type="file"
            name="image"
            onChange={handleImageChange}
          />
          <CameraOutlined />
        </FakeUpload>
        
          <Input
            rows={4}
            placeholder="พิมพ์ข้อความของคุณที่นี่"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            onPressEnter={sendMessage}
            style={{ width: '70%' }}
          />
          <Button type="primary" onClick={sendMessage} style={{ width: '22%' }}>
            ส่ง
          </Button>
        </InputContainer>
      </Container>
    </div>
  );
};

export default ChatApp;
