import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useSelector } from 'react-redux';
import {
  Box1Container,
  Balance,
  TabList,
  Tab,
  PriceName,
} from "./balance-box.styles"; // นำเข้า styled-components

const BalanceBox = () => {
  const currentUser = useSelector(state => state.auth.currentUser.username);
  const [userCredit, setUserCredit] = useState({});
  const [dailyIncome, setDailyIncome] = useState({});

  useEffect(() => {
    fetchData(currentUser);
    fetchIncome(currentUser);
  }, [currentUser]);

  const fetchData = (username) => {
    axios
      .get(`https://server.terminal-xpress.com/api/user-credit?username=${username}`)

      .then((response) => {
        setUserCredit(response.data[0]); // อ่านข้อมูลจาก response.data[0] เนื่องจาก API อาจส่งกลับข้อมูลเป็นอาร์เรย์
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };
  const fetchIncome = (username) => {
    axios
      .get(`https://server.terminal-xpress.com/api/daily-income?username=${username}`)

      .then((response) => {
        setDailyIncome(response.data[0]); // อ่านข้อมูลจาก response.data[0] เนื่องจาก API อาจส่งกลับข้อมูลเป็นอาร์เรย์
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };

  const formattedCredit = userCredit.credit ? userCredit.credit.toLocaleString() : '0';
  const formattedIncome = dailyIncome.grand_total ? dailyIncome.grand_total.toLocaleString() : '0';

  return (
    <Box1Container>
      <div className="box1-box">
        <Balance>ยอดรวม: {formattedCredit} บาท</Balance>
          <PriceName>รายได้วันนี้: {formattedIncome} บาท</PriceName>
        <TabList>
          <Tab to="/user-withdraw">ถอนเงิน</Tab>
        </TabList>
      </div>
    </Box1Container>
  );
};

export default BalanceBox;
