import React, { useState } from "react";
import FormInput from "../form-input/form-input.component";
import axios from "axios";
import { Button, message } from "antd";

const ProductForm = () => {
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [cost, setCost] = useState("");
  const [price, setPrice] = useState("");
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]); // เพิ่ม state เพื่อเก็บรูปภาพตัวอย่าง

  const handleImageChange = (e) => {
    const files = e.target.files;
    const selectedImages = [];
    const selectedImagePreviews = [];

    for (let i = 0; i < files.length; i++) {
      selectedImages.push(files[i]);

      // สร้าง URL สำหรับแสดงตัวอย่างรูปภาพ
      const imageURL = URL.createObjectURL(files[i]);
      selectedImagePreviews.push(imageURL);
    }

    setImages(selectedImages);
    setImagePreviews(selectedImagePreviews); // อัพเดทตัวอย่างรูปภาพ
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("productDescription", productDescription);
    formData.append("cost", cost);
    formData.append("price", price);

    for (let i = 0; i < images.length; i++) {
      formData.append("image", images[i]);
    }

    try {
      const response = await axios
.post("https://server.terminal-xpress.com/api/product", formData);


      if (response.status === 200) {
        console.log(response.data.message);
        message.success(response.data.message);
      } else {
        console.error('มีข้อผิดพลาดในการเพิ่มสินค้า');
        if (response.data.message) {
          message.error(response.data.message);
        } else {
          message.error('มีข้อผิดพลาดในการลงทะเบียน');
        }
      }
    } catch (error) {
      message.error("เกิดข้อผิดพลาดในการบันทึกสินค้า", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
    <h1>เพิ่มสินค้า</h1>
      <FormInput
        type="text"
        name="productName"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
        label="ชื่อสินค้า"
        required
      />
      <FormInput
        type="textarea"
        name="productDescription"
        value={productDescription}
        onChange={(e) => setProductDescription(e.target.value)}
        label="รายละเอียดสินค้า"
        required
      />
      <FormInput
        type="text"
        name="cost"
        value={cost}
        onChange={(e) => setCost(e.target.value)}
        label="ราคาตัวแทน"
        required
      />
      <FormInput
        type="text"
        name="price"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        label="ราคาขาย"
        required
      />
      <input
  type="file"
  name="productImage"
  onChange={handleImageChange}
  label="รูปภาพสินค้า"
  accept="image/*"
  multiple
  required
  style={{
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  }}
/>
<br />
      {/* แสดงรูปภาพตัวอย่าง */}
      {imagePreviews.map((preview, index) => (
        <img
          key={index}
          src={preview}
          alt={`รูปภาพ ${index + 1}`}
          style={{ maxWidth: "150px", maxHeight: "150px", margin: "10px" }}
        />
      ))}

      <br />
      <br />
      <Button type="primary" htmlType="submit">บันทึกสินค้า</Button>
    </form>
  );
};

export default ProductForm;
