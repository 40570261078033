import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/auth/auth.actions';
import axios from 'axios';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const onFinish = useCallback(async () => {
    try {
      // Make an API call using Axios
      const response = await axios.post('https://server.terminal-xpress.com/api/login', {

        phone,
        password,
      });

      if (response.status === 200) {
        const { permission, refcode, credit, score, userid, work_status, ...userData } = response.data;

        if (permission === '1' || permission === '2') {
          navigate('/dashboard');
        } else if (permission === '3') {
          navigate('/');
        }

        // Dispatch the login action with user data including userid
        dispatch(login({ permission, refcode, credit, score, userid, work_status, ...userData }));
      } else {
        console.error('Error logging in');
        message.error('Error logging in');
      }
    } catch (error) {
      console.error('Error sending data:', error);
      message.error('เบอร์โทรศัพท์หรือรหัสผิดพลาด');
    }
  }, [dispatch, navigate, phone, password]);

  return (
    <div>
      <h2>Login</h2>
      <Form onFinish={onFinish} name="normal_login"
      className="login-form">
        <Form.Item
          
          name="phone"
          rules={[
            {
              required: true,
              message: 'Please input your Phone number!',
            },
          ]}
        >
          <Input value={phone} onChange={(e) => setPhone(e.target.value)} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="เบอร์โทรศัพท์" />
        </Form.Item>
        <Form.Item
          
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Login
          </Button>
          <br />
          <br />
          <Link to="/register">register now!</Link>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
