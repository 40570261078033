import React, { useState, useEffect } from 'react';
import { Layout, Table, Input, Button, Select, message } from 'antd';
import axios from 'axios';
import NavBar from '../../../components/navbar/navbar.component';
import { useSelector } from 'react-redux';

const { Search } = Input;
const { Header, Content } = Layout;

const DistPage = () => {
  const currentUser = useSelector((state) => state.auth.currentUser.username);
  const [userData, setUserData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelectChange = (record, selectedProductIds) => {
    setSelectedOptions({
      ...selectedOptions,
      [record.id]: selectedProductIds,
    });
  };

  useEffect(() => {
    fetchData();
    fetchProduct();
  }, []);

  const fetchData = () => {
    axios
      .get('https://server.terminal-xpress.com/api/dist-log')

      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };

  const fetchProduct = () => {
    axios
      .get('https://server.terminal-xpress.com/api/product-list')

      .then((response) => {
        setProductData(response.data);
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการดึงข้อมูล: ', error);
      });
  };

  const assignProducts = (record) => {
    const selectedProductIds = selectedOptions[record.id] || [];
    const selectedProducts = productData.filter((product) =>
      selectedProductIds.includes(product.id.toString()) // เปลี่ยนเป็น id และแปลงเป็นสตริง
    );
    const postData = {
      selectedProducts: selectedProducts.map((product) => ({
        product_name: product.product_name,
        product_cost: product.cost,
      })),
      admin_username: currentUser,
      targetId: record.id,
      username: record.username,
    };

    axios
      .put(`https://server.terminal-xpress.com/api/assign-dist/${record.id}`, postData)

      .then((response) => {
        if (response.status === 200) {
          const updatedProductData = productData.filter((product) => product.id !== record.id);
          setProductData(updatedProductData);
          window.location.reload();
        } else {
          console.error('เกิดข้อผิดพลาดในการมอบหมาย');
          if (response.data.message) {
            message.error(response.data.message);
          } else {
            message.error('เกิดข้อผิดพลาดในการมอบหมาย');
          }
        }
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการมอบหมายรายการสินค้า:', error);
      });
  };

  const cancleAction = (record) => {
    axios.put(`https://server.terminal-xpress.com/api/cancle-action/${record.username}`)

      .then((response) => {
        if (response.status === 200) {
          const updatedProductData = productData.filter((product) => product.id !== record.id);
          setProductData(updatedProductData);
        } else {
          console.error('เกิดข้อผิดพลาดในการยกเลิก');
          if (response.data.message) {
            message.error(response.data.message);
          } else {
            message.error('เกิดข้อผิดพลาดในการยกเลิก');
          }
        }
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการยกเลิกรายการสินค้า:', error);
        message.error('เกิดข้อผิดพลาดในการยกเลิกรายการสินค้า');
      });
    axios.delete(`https://server.terminal-xpress.com/api/cancle-action/${record.id}`)

      .then((response) => {
        if (response.status === 200) {
          const updatedProductData = productData.filter((product) => product.id !== record.id);
          setProductData(updatedProductData);
          window.location.reload();
        } else {
          console.error('เกิดข้อผิดพลาดในการยกเลิก');
          if (response.data.message) {
            message.error(response.data.message);
          } else {
            message.error('เกิดข้อผิดพลาดในการยกเลิก');
          }
        }
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการยกเลิกรายการสินค้า:', error);
        message.error('เกิดข้อผิดพลาดในการยกเลิกรายการสินค้า');
      });
    
  };
  

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredData = userData.filter((user) => {
      return user.username.includes(value);
    });
    setUserData(filteredData);
  };

  const handleResetSearch = () => {
    setSearchValue('');
    fetchData();
  };

  const columns = [
    {
      title: 'เลขที่',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ชื่อผู้ใช้',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'เครดิตผู้ใช้',
      dataIndex: 'user_credit',
      key: 'user_credit',
      render: (user_credit) => (
        <span>
          {parseFloat(user_credit).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'ราคาสินค้า',
      dataIndex: 'product_cost',
      key: 'product_cost',
      render: (product_cost) => (
        <span>
          {parseFloat(product_cost).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
      render: (commission) => (
        <span>
          {parseFloat(commission).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: 'เริ่มเวลา',
      dataIndex: 'start_at',
      key: 'start_at',
    },
    {
      title: 'มอบหมายเวลา',
      dataIndex: 'allow_at',
      key: 'allow_at',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'ดำเนินการ',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        if (record.status === 'รอการร่วมซื้อ') {
          return (
            <Button
              type="primary"
              danger
              onClick={() => cancleAction(record)}
            >
              ยกเลิก
            </Button>
          );
        } else if (record.status === 'ร่วมซื้อแล้ว') {
          return <span>ไม่มีการดำเนินการ</span>;
        } else {
          return (
            <>
              <Select
                showSearch
                placeholder="เลือกรายการสินค้า"
                style={{ width: '400px' }}
                value={selectedOptions[record.id] || []}
                onChange={(selectedProductIds) =>
                  handleSelectChange(record, selectedProductIds)
                }
              >
                {productData.map((item) => (
                  <Select.Option key={item.id} value={item.id.toString()}>
                    {`${item.product_name} / ราคาตัวแทน ${item.cost}`}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type="primary"
                onClick={() => assignProducts(record)}
              >
                มอบหมาย
              </Button>
            </>
          );
        }
      },
    }
  ];

  return (
    <Layout style={{ height: '100vh', padding: 0 }}>
      <Layout>
        <NavBar />
        <Header style={{ padding: '0 16px', background: '#001529', color: 'white' }}>
          <h1 style={{ fontSize: '24px', margin: '0', fontFamily: 'Noto Sans Thai' }}>มอบหมายคำสั่งซื้อ</h1>
        </Header>
        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
          <Search
            placeholder="ค้นหา..."
            enterButton="ค้นหา"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearch}
            style={{ marginBottom: '16px' }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Button type="primary" onClick={handleResetSearch}>
              รีเซ็ตค้นหา
            </Button>
          </div>
          <Table dataSource={userData} columns={columns} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DistPage;
