import React from "react";

import { ItemContainer, OrderImage, DetailContainer, NameItem, Desc } from './dir-item.styles';

const DirItem = ({ name, description, imgUrl }) => {
  // Update the imgUrl to include "server." in front of "terminal-xpress.com"
  const updatedImgUrl = imgUrl.replace(
    'https://terminal-xpress.com/',
    'https://server.terminal-xpress.com/'
  );

  return (
    <ItemContainer>
      <OrderImage src={updatedImgUrl} />
      <DetailContainer>
        <NameItem>{name}</NameItem>
        <Desc>{description}</Desc>
      </DetailContainer>
    </ItemContainer>
  );
};

export default DirItem;
