import styled from 'styled-components';

export const ListContainer = styled.div`
  display: block;
  margin: 20px 0;
  padding: 10px;
  width: auto;
`;

export const HeadTitle = styled.div`
  font-size: 24px;
  margin-left: 20px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const NoItem = styled.p`
  font-size: 18px;
  color: gray;
  margin: auto; /* จัดให้ NoItem อยู่กึ่งกลางทั้งแนวนอนและแนวตั้ง */
  text-align: center; /* จัดให้เนื้อหาภายใน NoItem อยู่กึ่งกลางตามแนวนอน */
`;
